import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import CreateView from '../views/Create.vue'
import ResultView from '../views/Result.vue'
import TestView from '../views/Test.vue'
import AttendeeView from '../views/Attendee.vue'
import FriendshipView from '../views/Friendship.vue'
import ContactView from '../views/Contact.vue'
import PrivacyView from '../views/Privacy.vue'
import TosView from '../views/Tos.vue'
import ImprintView from '../views/Imprint.vue'
import Error404 from '@/views/Error404.vue'

const router = createRouter(
{
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView,
		},
		{
			path: '/create',
			name: 'create',
			component: CreateView,
		},
		{
			path: '/result',
			name: 'result',
			component: ResultView,
		},
		{
			path: '/test/:id',
			name: 'test',
			component: TestView,
		},
		{
			path: '/test/:quizId/attendee/:attendeeId',
			name: 'attendee',
			component: AttendeeView,
		},
		{
			path: '/friendship',
			name: 'friendship',
			alias: ['/freundschaft'],
			component: FriendshipView,
		},
		{
			path: '/contact',
			name: 'contact',
			component: ContactView,
		},
		{
			path: '/privacy',
			name: 'privacy',
			component: PrivacyView,
		},
		{
			path: '/tos',
			name: 'tos',
			component: TosView,
		},
		{
			path: '/imprint',
			name: 'imprint',
			component: ImprintView,
		},
		{
			path: '/:pathMatch(.*)',
			name: '404',
			component: Error404,
		}
	]
})

export default router
