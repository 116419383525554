<script setup lang="ts">
import { getCurrentHostname, getLocale } from '@/functions'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n()

const currentHost = getCurrentHostname()

useHead({
	link: [
		{
			rel: 'canonical',
			href: `${currentHost}${t('friendship.head.url')}`,
		}
	]
})
</script>

<template>
	<div>
		<section>
			<h2>{{ $t('friendship.title') }}</h2>
			<div v-if="locale === 'de'">
				<p>
					„Freundschaft, das ist eine Seele in zwei Körpern“ – schon Aristoteles wusste um den Wert der Freundschaft und bis heute schätzen wir sie als eines der höchsten Güter in unserem Leben. Freunde sind Menschen, die uns so annehmen, wie wir sind. Ich meine wirklich so, wie wir sind – Menschen, vor denen wir laut denken können, mit denen wir herumblödeln und unangemessene Witze machen dürfen, ohne uns dabei komisch oder peinlich zu fühlen. Weil wir einfach wissen: Wir werden gemocht wie wir sind, ohne, dass wir uns verstellen, „instagramtauglich“ aussehen oder uns zurücknehmen müssen.
				</p>
				<p>
					Wir können uns auf unsere Freunde verlassen – in guten Zeiten freuen sie sich mit uns und gönnen uns unseren Erfolg, in schlechten lassen sie uns nicht allein und bestärken uns in unserem Ich und unserem Wert. Freunde sind ehrlich – sie sagen und zeigen uns, wenn sie etwas an uns wertschätzen genauso, wie wenn sie sich über etwas an uns ärgern – sie zeigen uns ihre Gefühle, denn auch sie wissen, dass sie angenommen werden – so wie sie sind. Freundschaft heißt Gegenseitigkeit. Und auch, wenn wir ihnen manchmal unendlich auf die Nerven gehen, sie vielleicht manchmal auch nicht gut behandeln oder im Stich lassen – wahre Freundschaft übersteht tiefe Abgründe ebenso wie unglaubliche Höhenflüge.
				</p>
				<p>
					Ob gemeinsam oder jeder für sich – egal was wir erleben, unsere Freunde begleiten uns auf unserem Weg. Sie sind unersetzlich für unser Glück. Manche Freunde haben wir schon jahrelang, andere vielleicht erst seit ein paar Monaten – ganz egal wie lange ihr euch kennt: Wenn ihr euch schätzt, füreinander da seid und miteinander lachen könnt, dann genießt eure gemeinsame Zeit. Es werden viele Menschen in dein Leben kommen – doch wahre Freunde bleiben, für immer!
				</p>
				<p>
					Wie gut die Freundschaft ist lässt sich natürlich auch daran erkennen, wie viel ihr voneinander wisst. Habt ihr schon alles Mögliche miteinander erlebt und geteilt oder macht ihr gerade erst viele gemeinsame Erfahrungen? Kennt ihr euch in und auswendig oder ist da noch Luft nach oben? <a href="/?ref=friendship">Macht den Test</a> und findet es heraus oder finde heraus welcher <a href="/personality-test?ref=friendship">Freundschaftstyp</a> du bist. 
				</p>
				<p>
					Wir wünschen euch viel Spaß beim Testen eurer Freundschaft!
				</p>
			</div>
			<div v-else>
				<p>
					„Friendship is a Soul dwelling in two bodies.“ - Aristotle already knew about the value of friendship and to this day we value it as one of the highest goods in our lives. Friends are people who accept us for who we are. I really mean who we are - people we can think out loud in front of, who we can fool around and make inappropriate jokes with without feeling weird or embarrassed. Because we simply know: We are liked for who we are, without us having to pretend, look „Instagram-compatible“ or have to withdraw.
				</p>
				<p>
					We can rely on our friends - in good times they rejoice with us and grant us our success, in bad times they don't leave us alone and strengthen us in our self and our self-worth. Friends are honest - they tell us and show us when they value something in us just as much as when they are angry about something in us - they show us their feelings, because they too know that they are accepted - just as they are. Friendship means reciprocity. And even if we sometimes get on their nerves infinitely, sometimes we don't treat them well or let them down - true friendship survives deep abysses as well as unbelievable heights.
				</p>
				<p>
					Whether together or individually - no matter what we experience, our friends accompany us on our way. They are irreplaceable for our happiness. Some friends we have had for years, others maybe only for a few months - no matter how long you've known each other: If you appreciate each other, are there for each other and can laugh together, then enjoy your time together. Many people will come into your life - but stay true friends forever!
				</p>
				<p>
					How good the friendship is can of course also be seen by how much you know about each other. Have you already experienced and shared all kinds of things with each other or are you only just having a lot of common experiences? Do you know each other inside out or is there still room for improvement? <a href="/?ref=friendship">Take the test</a> and find out or find out what <a href="/personality-test?ref=friendship">type of friend</a> you are.
				</p>
				<p>
					We wish you a lot of fun testing your friendship!
				</p>
			</div>
		</section>
	</div>
</template>

<style lang="css" scoped>
</style>