import { db, functions, storage } from "@/firebase"
import type IAttendeeClient from "@/interfaces/IAttendeeClient"
import type IAttendeeRes from "@/interfaces/IAttendeeRes"
import type IQuestionAnswerPair from "@/interfaces/IQuestionAnswerPair"
import type IQuestionRes from "@/interfaces/IQuestionRes"
import type IQuizClient from "@/interfaces/IQuizClient"
import type IQuizQuestions from "@/interfaces/IQuizQuestions"
import { getDownloadURL, ref } from "firebase/storage"
import * as lang from '../i18n/index'
import type ILang from "@/interfaces/ILang"
import { i18n } from "@/i18n/i18n"
import { useLocalStorage } from "@vueuse/core"
import { httpsCallable } from "firebase/functions"
import { collection, doc, getDoc, Timestamp } from "firebase/firestore"
import type IQuizRes from "@/interfaces/IQuizRes"
//import enLang from '../i18n/en.json'
const enLang = await import('../i18n/en.json')

const getQuizFb = httpsCallable<{
	quizId: string,
},
{
	quiz: IQuizRes,
	error:
	{
		message: string,
		code: number
	}
}>(functions, 'getQuiz')

const getAttendeeFb = httpsCallable<{
	quizId: string,
	attendeeId: string,
},
{
	attendee: IAttendeeRes,
	error:
	{
		message: string,
		code: number
	}
}>(functions, 'getAttendee')

export const getQuiz = async ( quizId: string ) =>
{
	const result = await getQuizFb({
		quizId,
	})
	
	if( !result.data )
	{
		throw new Error( i18n.global.t('functions.errors.no_quiz') )
	}
	else if( !result.data.quiz )
	{
		throw new Error( result.data.error.message )
	}

	const data = result.data.quiz

	return {
		id: data.id,
		name: data.name,
		sex: data.sex,
		created: Timestamp.fromMillis(data.created as number),
		questions: data.questions,
		msgToken: data.msgToken,
	} as IQuizClient
}

export const getAttendee = async ( quiz: IQuizClient, attendeeId: string ) =>
{
	const attendeeDoc = await getDoc( doc( db, `/quizzes/${quiz.id}/attendees`, attendeeId ) )
	
	if( !attendeeDoc.exists() )
	{
		throw new Error( i18n.global.t('functions.errors.no_attendee') )
	}
		
	const data = Object.assign({id: attendeeDoc.id}, attendeeDoc.data() as IAttendeeRes)
	const questions = transformQuizQuestionsToPair( quiz.questions as IQuizQuestions )

	return {
		id: data.id,
		name: data.name,
		sex: data.sex,
		created: data.created,
		message: data.message,
		answers: data.answers,
		ownQuiz: data.ownQuiz,
		correctAnswers: evaluateCount( questions, data.answers ),
		allAnswers: Object.values( data.answers ).length,
		new: false,
	} as IAttendeeClient
}

export const getQuestionImage = async ( file: string | null ) =>
{
	if( !file )
	{
		return null
	}

	const url = await getDownloadURL(ref( storage, `questions/${file}` ))

	await new Promise((res, rej) =>
	{
		const img = new Image()
		img.onload = res
		img.onerror = rej
		img.src = url
	})

	return url
}

export const getQuestionAnswers = ( question: IQuestionRes, name: string, sex: string, custom: string | null ) =>
{
	const lang = i18n.global.locale
	const answers = question.answers.map((ans, idx) =>
	{
		let genderAnswer = null
		switch( sex )
		{
			case 'male':
				genderAnswer = ( ans.male && lang in ans.male ) ? ans.male[lang] : null
			break
			case 'female':
				genderAnswer = ( ans.female && lang in ans.female ) ? ans.female[lang] : null
			break
		}

		return { idx: idx.toString(), answer: parseText( custom ? ( ( ( ans.me && lang in ans.me ) ? ans.me[lang] : null ) || ans.answer[lang] ) : ( genderAnswer || ans.answer[lang] ), name ), custom: false }
	})

	if( custom )
	{
		answers.push({idx: 'custom', answer: custom, custom: true})
	}

	return answers.sort(() => 0.5 - Math.random())
}

export const evaluateCount = ( questions: IQuestionAnswerPair, answers: IQuestionAnswerPair ) =>
{
	let c: number = 0
	for( const [qId, value] of Object.entries( questions ) )
	{
		if( answers[qId] && value === answers[qId] )
		{
			c++
		}
	}

	return c
}

export const evaluateAnswers = ( questions: IQuestionAnswerPair, answers: IQuestionAnswerPair ) =>
{
	for( const [qId, value] of Object.entries( questions ) )
	{
		if( answers[qId] && value === answers[qId] )
		{
			return true
		}
	}

	return false
}

export const transformQuizQuestionsToPair = ( questions: IQuizQuestions ) =>
{
	const map: IQuestionAnswerPair = {}

	for( const [key, val] of Object.entries( questions ) )
	{
		map[key] = val.answer	
	}

	return map
}

export const getAttendeeResult = ( perc: number, name: string ) =>
{
	if( perc === 100 )
	{
		return i18n.global.t('functions.attendee_result.perc100', {name})
	}
	else if( perc >= 75 )
	{
		return i18n.global.t('functions.attendee_result.perc75', {name})
	}
	else if( perc >= 50 )
	{
		return i18n.global.t('functions.attendee_result.perc50', {name})
	}
	else if( perc >= 25 )
	{
		return i18n.global.t('functions.attendee_result.perc25', {name})
	}

	return i18n.global.t('functions.attendee_result.perc0', {name})
}

export const getCustomTestHeader = (name: string) =>
{
	return i18n.global.t('custom_title', {name: getGenitiveName(name)})
}

export const getCurrentHostname = () =>
{
	return `${window.location.protocol}//${window.location.hostname}`
}

export const getLocale = () =>
{
	const ls = useLocalStorage<{lang: string | null}>('l', {lang: null})

	if( ls.value.lang )
	{
		return ls.value.lang
	}

	if( import.meta.env.DEV )
	{
		switch( window.location.hostname )
		{
			case 'ftde.localhost': return 'de'
			case 'ften.localhost': return 'en'
		}
	}
	else
	{
		for(const [lang, meta] of Object.entries<ILang>(getLanguages()))
		{
			if( meta.domain === window.location.hostname )
			{
				return lang
			}
		}
	}

	return 'en'
}

export const getCurrentLanguage = () =>
{
	for(const [lang, meta] of Object.entries<ILang>(getLanguages()))
	{
		if( lang === i18n.global.locale )
		{
			return Object.assign({lang: i18n.global.locale}, meta)
		}
	}

	return Object.assign({lang: 'en'}, enLang.meta)
}

export const getLanguages = () =>
{
	const langs: {[key: string]: ILang} = {}
	Object.entries(lang).forEach(l =>
	{
		langs[l[0]] = l[1].meta
	})

	return langs
}

export const getManifest = () =>
{
	const langs: {[key: string]: {[key: string]: string}} = {}
	Object.entries(lang).forEach(l =>
	{
		langs[l[0]] = l[1].manifest
	})

	return langs
}

export const changeLanguage = (lang: string) =>
{
	const ls = useLocalStorage<{lang: string | null}>('l', {lang: null})
	i18n.global.locale = lang
	ls.value.lang = lang
}

export const getGenitiveName = (name: string) =>
{
	if( name.endsWith('s') || name.endsWith('x') || name.endsWith('z') )
	{
		return `${name}'`
	}

	return `${name}'s`
}

export const parseText = (text: string, name: string) =>
{
	return text.replaceAll('[G_NAME]', getGenitiveName(name)).replaceAll('[NAME]', name)
}